import React, { useEffect, useRef } from "react";

import { graphql } from "gatsby";
import { useTranslation } from "gatsby-plugin-react-i18next";

import { Helmet } from "react-helmet";
import * as style from "../styles/index.module.css";

//Datos
import JsonData from "../data/indexContenido.json";
import Data from "../data/data.json";

//Imagenes
import COBASA from "../images/empresas/cobasa_s.webp";
import DIGITALIA from "../images/empresas/digitaliaGBE_s.webp";
import HTV from "../images/empresas/htv_s.webp";
import NORTIC from "../images/empresas/nortic_s.webp";
import PPNOR from "../images/empresas/ppnor_s.webp";
import TASA from "../images/empresas/tasa_s.webp";

//Componentes
import Layout from "../components/layout/Layout";
import { Button, ButtonNewPage } from "../assets/Button.js";
import { Returntop } from "../assets/Returntop";
import { ReadMore } from "../assets/ReadMore";
import Carrusel from "../assets/Carrusel.js";
import { Script } from "gatsby";

// Capturar eventos para los clics en las webs de cada empresa
import { OutboundLink } from "gatsby-plugin-google-gtag";

// Toastify
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

let contact_page = Data[0].contact_page;
let cookiebot_ID = process.env.GATSBY_API_COOKIEBOT_ID;

const IndexPage = () => {
  const { t } = useTranslation();

  // Mostrar toast para formulario enviado correctamente
  useEffect(() => {
    if (!(localStorage.getItem("toastOK") === null)) {
      toast.success(t("toast.ok"), { autoClose: 1500, toastId: "toastOK" });
      localStorage.removeItem("toastOK");
    }
  });

  /* Añadir nueva empresa al import */
  function getImg(title) {
    if (title === "COBASA") return COBASA;
    else if (title === "DIGITALIA GBE") return DIGITALIA;
    else if (title === "HTV") return HTV;
    else if (title === "NORTIC") return NORTIC;
    else if (title === "PPNOR") return PPNOR;
    else if (title === "TASA") return TASA;
  }

  /*
    Plantila para un empresa con su logo "Image" y "flex" es el estilo dependiendo si se pinta hacia la izquierda o la derecha,
    sí es para móviles da igual porque se pintara de arriba a abajo.
   */
  const Empresa = ({
    index,
    title,
    subtitle,
    paragraph,
    color,
    link,
    flex,
    button,
  }) => {
    const Image = {
      backgroundImage: `url(${getImg(title)})`,
      backgroundRepeat: "no-repeat",
      backgroundPosition: "center",
      backgroundSize: "contain",
    };

    return (
      <div
        key={index}
        id={title}
        className={style.containerEmpresa}
        style={{ flexDirection: flex }}
      >
        <div className={style.middleContainerEmpresas}>
          <h2 style={{ color: color }}>{title}</h2>
          <h3>{subtitle}</h3>
          <p>{paragraph}</p>
          <ButtonNewPage
            style={{
              backgroundColor: color,
              borderColor: color,
              width: "fit-content",
            }}
            link={link}
          >
            {button}
          </ButtonNewPage>
        </div>
        <div className={style.imageContainerEmpresas}>
          {/* blank y noreferrer es para abrir el enlace en una pestaña nueva */}
          <OutboundLink href={link} target="_blank" rel="noreferrer">
            <div className={style.imageEmpresa} style={Image} title={title} />
          </OutboundLink>
        </div>
      </div>
    );
  };

  const NuestrasEmpresas = ({ id, contenido }) => {
    return contenido.map((data, index) => {
      if (index % 2 === 0) {
        return (
          /* Empresa orientada a la derecha */
          <Empresa
            index={index}
            title={t(`Index.${id}.${data.empresa}.title`)}
            subtitle={t(`Index.${id}.${data.empresa}.subtitle`)}
            paragraph={t(`Index.${id}.${data.empresa}.paragraph`)}
            color={data.color}
            link={data.link}
            flex="row"
            button={t(`Index.${id}.${data.empresa}.button`)}
          />
        );
      } else {
        /* Empresa orientada a la izquierda */
        return (
          <Empresa
            index={index}
            title={t(`Index.${id}.${data.empresa}.title`)}
            subtitle={t(`Index.${id}.${data.empresa}.subtitle`)}
            paragraph={t(`Index.${id}.${data.empresa}.paragraph`)}
            color={data.color}
            link={data.link}
            flex="row-reverse"
            button={t(`Index.${id}.${data.empresa}.button`)}
          />
        );
      }
    });
  };

  const AcercaNosotros = ({ index, data }) => {
    return (
      //Acerca de Nosotros
      //Imagen de Fondo
      <div key={data.id + index} className={style.acercaNosotrosContainer}>
        {/* Vector con fondo transparente */}
        <div className={style.grupoGofContainer}>
          {/* Contenido */}
          <div className={style.contenidoGrupoGof}>
            <h1 index={index} id={data.id} className={style.title}>
              {t(`Index.${data.id}.${data.title}`)}
            </h1>
            <ReadMore style={{ position: "relative" }}>
              <p className={style.acercaNosotrosTexto}>
                {t(`Index.${data.id}.${data.descripcion}`)}
              </p>
              <div className={style.acercaNosotrosExtenderTexto}>
                {t(`Index.${data.id}.${data.readmore}`)}
              </div>
            </ReadMore>
            <div className={style.buttonsContainerGrupoGof}>
              <Button link={contact_page} isLink={true}>
                {t(`Index.${data.id}.${data.button1}`)}
              </Button>
              <Button
                style={{ backgroundColor: "transparent", color: "#578c75" }}
                link="#empresas"
              >
                {t(`Index.${data.id}.${data.button2}`)}
              </Button>
            </div>
          </div>
        </div>
      </div>
    );
  };

  const CodigoConducta = ({ text, button }) => {
    return (
      <>
        <p className={style.textContent}>{text}</p>
        <a
          className={style.buttonConducta}
          href="../static/Codigo_Conducta_GOF.pdf"
        >
          <p style={{ display: "flex", alignSelf: "center" }}>{button}</p>
          <div className={style.downloadImage} />
        </a>
      </>
    );
  };

  return (
    <Layout href="" link="">
      {JsonData.map((data, index) => {
        //Añadir los diferentes ID, dependiendo de las secciones que quieras en la página web
        if (data.id === "nosotros") {
          return <AcercaNosotros index={index} data={data} />;
        } else if (data.id === "valores") {
          return (
            //Imprimir Nuestros Valores
            <>
              <h1 key={index} id={data.id} className={style.title}>
                {t(`Index.${data.id}.${data.title}`)}
              </h1>
              {/* Esta linea fuerza a react/gatsby a hacer un Rerender del carrusel cuando el componente window de JS esta disponible en entorno de despliegue */}
              {
                typeof window !== "undefined" ? (
                  <Carrusel
                    ID={data.id}
                    title={data.title}
                    contenido={data.contenido}
                  />
                ) : (
                  <div style={{ height: 400 }}></div>
                )
                /*<Carrusel key="placeholder" ID={data.id} title={data.title} contenido={data.contenido}/>*/
              }
            </>
          );
        } else if (data.id === "empresas") {
          return (
            //Imprimir Nuestras Empresas
            <>
              <h1 key={index} id={data.id} className={style.title}>
                {t(`Index.${data.id}.${data.title}`)}
              </h1>
              <NuestrasEmpresas id={data.id} contenido={data.contenido} />
            </>
          );
        } else {
          // Sección de descarga del Código de Conductas
          return (
            <CodigoConducta
              text={t(`Index.${data.id}.text`)}
              button={t(`Index.${data.id}.button`)}
            />
          );
        }
      })}
      <Returntop posX={25} posY={25} size={65} factor={0.5} />

      <ToastContainer
        position="top-center"
        hideProgressBar={false}
        newestOnTop
        closeOnClick
        rtl={false}
        pauseOnFocusLoss={false}
        draggable
        pauseOnHover={false}
        theme="colored"
      />
    </Layout>
  );
};

export default IndexPage;

export function Head() {
  return (
    <>
      <Helmet>
        <html lang="es" />
        <title>GRUPO GOF</title>
        <meta name="description" content="Página desarrollada por Nortic" />
        <link
          rel="icon"
          href="https://grupogof.com/wp-content/uploads/2018/10/icono-300x300.png"
          type="image/png"
        ></link>
      </Helmet>
      <Script
        id="Cookiebot"
        src="https://consent.cookiebot.com/uc.js"
        data-cbid={cookiebot_ID}
        data-cookieconsent="statistics"
        data-blockingmode="auto"
        type="text/plain"
        strategy="idle"
      ></Script>
    </>
  );
}

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
